import React, { useRef } from 'react';

import { Helmet } from 'react-helmet';

import { VncScreen } from 'react-vnc';
import { Circles } from 'react-loader-spinner'

function App() {
    const ref = useRef<React.ElementRef<typeof VncScreen>>(null);
    const [ isConnected, setConnected ] = React.useState<boolean>(false);

    const handleConnected = () =>
    {
        setConnected(true)
    };

    const handleDisconnected = () =>
    {
        setConnected(false)
    };


    let loading = <Circles
                      color="#22BAF5"
		      width="100%"
		      height="100%"
                    />

    return (
        <div className="application">
	  <Helmet>
	    <style>{'body { background-color: black; }'}</style>
	  </Helmet>
	  <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center', height: '100vh', width: '100%'}}>
            <VncScreen
              url='wss://nuvo.limntech.com:6081'
              qualityLevel={9}
              compressionLevel={2}
              ref={ref}
              loadingUI={loading}
	      onConnect= {handleConnected}
	      onDisconnect = {handleDisconnected}
	      scaleViewport
	      style={{
	        display: `${isConnected ? 'block' : 'none'}`,
	        height: "100vh",
		width: "133vh"
              }}
            />
	  </div>
	</div>
    );
}

export default App;
